<template>
<div class="about-view">
  <div class="wrapper">
    <div class="banner-about">
      <div class="title">"If the human brain were so simple that we could understand it, we would be so simple that we couldn’t."</div>
      <div class="name">- Emerson M. Pugh</div>
    </div>
    <div class="about">
      <h1>About Us</h1>
      <div class="about-content">
        <p>MyNewsScan is a news aggregator project, coordinated by Dr. Gedi Luksys and Dr. Robin Hill from the University of Edinburgh and implemented by </p>
        <p>UoE students/graduates</p>
        <p class="name-title">Web design & development:</p>
        <p>Ahsun Tariq, Yuhang Tang, Yichen Li, Yuqi Xiao, Debbie Poon, Kungang Li, Zhewen Du, Xinyue Huang, Mariana Martinez Juarez, Jiahe Lyu, Pinhua Wang, Jiachen Cai, Ziyi Zhang, Yiyun Zhu, Jingxian Zhao</p>
        <p class="name-title">News selection, annotation, experiments and analyses:</p>
        <p>Jia Wei Tan, Marianna Hronska, Yiyang Zhang, Yining Yang, Maizi Fang, Clara Lopez Velasco, Wenyuan Zhu, Hanyu Li, Kaixuan Li, Puzhi Yu, Yilin Lu, Yanyi Sụn, Lei Zhong, Haiyun Kong, Kim Ngan Linda Nguyen, Jiachen Zheng, Xiaoying Wang, Siyuan Lü, Xinyi Cai </p>
        <br>
        <p>Its main purpose is to provide useful, diverse and high quality news selection of various topics to international readers. We also collect readers' feedback about the articles to investigate the role of schemas on learning, decision making, and more generally on how factors such as perceived news accuracy, familiarity, readability, usefulness and changing one's view affect their performance and popularity.</p>
        <p>At present, MyNewsScan is not a commercial entity.</p>
      </div>
      <div class="about-line"></div>
      <h1>USER LEVELS</h1>
      <div class="about-content">
        <p>We are dedicating to set up a group from users, inviting those who are willing to build our community a better place to moderate the posts and manage the websites.</p>
        <p>In details, we tagged every single user, from regular user to <span style="color: blue; font-weight: bolder;">"super"</span> administrator.</p>
        <p><el-tag type="success">Regular</el-tag> or Level 0: <span style="font-style: italic;">most regular users, no management authority, but can comment on articles and public groups, join private groups by invite, rate articles and other comments, propose articles and questions. Active participation in reading, comments and offering articles can lead to advancement to Level 1.</span></p>
        <p><el-tag >Moderator</el-tag> or Level 1: <span style="font-style: italic;">in addition to Level 0 permissions, moderators can moderate articles and questions proposed by other users. Active engagement, good record of moderation and/or other work on the project can lead to advancement to Level 2 after appropriate vetting.</span></p>
        <p><el-tag type="warning">Admin</el-tag> or Level 2: <span style="font-style: italic;">in addition to Level 1 permissions, admins have substantial access for management of users, moderators, and access to collected data, as well as ability to edit article data and create new discussion groups in the Forum.</span></p>
        <p><el-tag type="danger">Super</el-tag> or Level 3: <span style="font-style: italic;">in addition to Level 2 permissions, super admins are project executives who have access to all remaining functionality of the site, including visibility of and possibility to delete any comments or posts in the Forum, potentially deleting users (in case of malpractice) and articles (for editorial reasons).</span></p>
      </div>
      <div class="about-line"></div>
      <h1>Contact Us</h1>
      <div class="about-content">
        For any inquiries, suggestions or complaints, please email <a href="mailto:gedi.luksys@ed.ac.uk">gedi.luksys@ed.ac.uk</a><br>
        This project is currently under development, so we very much welcome your input.
      </div>
      
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.banner-about{
  display: flex;
  width: 1240px;
  height: 180px;
  /*padding: 0 11%;*/
  font-size: 28px;
  color: #14279B;
  margin: 0 auto;
  line-height: 1.5;
  font-family: "Poppins-SemiBold";
  /*border: 1px solid;*/
  border-radius: 0 0 80px 80px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background:url('../assets/images/bg-banner-about.jpg') no-repeat center top;
}
.banner-about .title{
  text-align: center;
  width: 1000px;
  margin: 0 auto;
}
.banner-about .name{
  text-align: right;
  width: 1000px;
  margin: 0 auto;
}
.about{
  display: block;
  width: 1085px;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;
}
.about h1{
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 32px;
  font-family: "Poppins-Bold";
  color: #14279B;
}
.about-content{
  font-size: 14px;
  color: #000000;
  line-height: 1.8;
  padding-bottom: 30px;
}
.about-content a{
  color: #4A88F8;
}
.about-content a:hover{
  text-decoration: underline;
}
.about-content .imgs img{
  display: block;
  margin: 0 auto;
}
.name-title{
  font-family: "Poppins-Bold";
}


.about-line{
  display: block;
  height: 1px;
  width: 100%;
  margin-bottom: 35px;
  background-color: #d9d9d9;
}
</style>